import React from 'react'
import bgImg from './img/home-3-parallax-1-1024x4272-2.jpg'
import home from './img/home.png'
import support from './img/support.png'
import easy from './img/easy.png'
import trust from './img/trust.png'

export default function WhyChooseUs() {
  return (
    <>
    <section className='bg-imgg d-none'>
        <img src={bgImg} alt="img" className="img-fluid" />
    </section>

    <section className='pb-5 position-relative'>
        <div className="container">
            <div className='row'>
                <div className="col-md-12">
                    <h2 className='text-center text-dark pb-4'>WHY <span className='main-color fw-normal'>CHOOSE US</span></h2>
                </div>
            </div>
            <div className="row ">
                

                <div className="col-md-3">
                    <div className="why-box text-center">
                        <img src={home} alt="img" className='img-fluid' />
                        <h5 className='text-uppercase'>Interior for home</h5>
                        <p>Unlock your home’s potential with our expert interior designers in Hyderabad. At Elaan Interiors, we craft personalized, stunning interiors that resonate with your style. Let us bring your vision to life.</p>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="why-box text-center">
                        <img src={trust} alt="img" className='img-fluid' />
                        <h5 className='text-uppercase'>trusted by thousands</h5>
                        <p>Our dedicated designers consistently create visually captivating and functional spaces, earning the trust of a diverse clientele and consistently exceeding expectations.</p>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="why-box text-center">
                        <img src={easy} alt="img" className='img-fluid' />
                        <h5 className='text-uppercase'>financing made easy</h5>
                        <p>Experience effortless financing with us. We’ve streamlined the process, making it easy and hassle-free. Get the funds you need without the stress. Your financial goals are within reach.</p>
                    </div>
                </div>


                <div className="col-md-3">
                    <div className="why-box text-center">
                        <img src={support} alt="img" className='img-fluid' />
                        <h5 className='text-uppercase'>24/7 SUPPORT</h5>
                        <p>Get round-the-clock assistance from our skilled interior designers for all your design needs. Our experts are available 24/7 to transform your space into something extraordinary.</p>
                    </div>
                </div>


            </div>
        </div>
    </section>
    </>
  )
}
