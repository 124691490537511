import React from 'react'
import abi from '../img/about.png'
import home from '../img/home.png'
import support from '../img/support.png'
import easy from '../img/easy.png'
import trust from '../img/trust.png'
export default function AboutUs() {
  return (
    <>
    <section className='bg-primary-1'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1 className='text-white'>About</h1>
            <h4>Transforming Your Vision into Reality</h4>
          </div>
          <div className="col-md-6">
            <img src={abi} alt="img" className='img-fluid' />
          </div>
        </div>
      </div>
    </section>

    <section className='aboutt'>
      <div className="container py-5">
        <div className="row align-items-center justify-content-center position-relative">        
            
       
          <div className="col-md-10 home-text text-center">
              <h2 className="fw-meduim py-3">About <span className='main-color fw-normal'>A3G INTERIO</span> </h2>
              <h5 className="text-uppercase mt-0 main-color">Transforming Your Vision into Reality</h5>
              
              <h2 className="h5 fw-normal pt-3">Welcome to A3G Interio, your trusted partner in innovative interior design solutions for both residential and commercial spaces.</h2>
              <h3 className="h6 fw-normal pt-3">Founded by <b>Ar. Ghufran Mujahid</b>, A3G Interio blends creativity with functionality to bring to life spaces that reflect your personality, style, and brand. With years of experience in architectural design and a passion for creating unique environments, Ar. Mujahid leads a dynamic team of skilled professionals dedicated to delivering exceptional designs tailored to your specific needs.</h3>
              <h3 className="h6 fw-normal pt-3">At A3G Interio, we believe in the power of collaboration. That’s why we’ve partnered with Lakhotia College of Design, Hyderabad, incorporating fresh talent from their pool of students into our projects. This synergy allows us to bring fresh, innovative perspectives to every design while providing invaluable real-world experience to the next generation of designers.</h3>
              
          </div>
          <div className="col-md-6">
                                         
                
              
          </div>            
        </div>   
        </div>  
    </section>

    <section className='ab-page'>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h3 className='main-color'>What we offer</h3>
            <h5 className='h5 fw-normal'>Residential Design: From contemporary homes to luxurious apartments, we create spaces that are functional, beautiful, and personalized to suit your lifestyle.</h5>
          </div>
        </div>

        <div className='row py-4'>
          <div className='col-md-3'>
            <div className='p-3 shadow'>
              <img src={home} alt="" className='img-fluid' />
              <h5 className='main-color'>Residential Design</h5>
              <p className=''>From contemporary homes to luxurious apartments, we create spaces that are functional, beautiful, and personalized to suit your lifestyle.</p>
            </div>
          </div>

          <div className='col-md-3'>
            <div className='p-3 shadow'>
              <img src={easy} alt="" className='img-fluid' />
              <h5 className='main-color'>Commercial Design</h5>
              <p>Our commercial design solutions are crafted to enhance the aesthetics, functionality, and brand image of your business, whether it’s an office, retail space, or hospitality venue.</p>
            </div>
          </div>

          <div className='col-md-3'>
            <div className='p-3 shadow'>
              <img src={trust} alt="" className='img-fluid' />
              <h5 className='main-color'>Custom Solutions</h5>
              <p>We take a bespoke approach to each project, working closely with you to understand your vision and translate it into stunning, practical interiors.</p>
            </div>
          </div>

          <div className='col-md-3'>
            <div className='p-3 shadow'>
              <img src={support} alt="" className='img-fluid' />
              <h5 className='main-color'>Profit-Sharing Model</h5>
              <p>Our unique business model ensures that every member of our design team, from seasoned architects to budding student designers, shares in the success of the project. This approach fosters a collaborative, motivated environment that drives exceptional results.</p>
            </div>
          </div>

          
        </div>

        <div className="row justify-content-center py-4">
          <div className="col-md-12">
            <h3 className='main-color pb-2 pt-3'>Our Philosophy</h3>
            <h4 className='fw-normal'>We believe in designing spaces that not only look good but also improve the way you live and work. Our approach is rooted in a deep understanding of your needs, combined with a passion for design excellence. Each project is a blend of creativity, innovation, and precision, ensuring a harmonious balance between form and function.</h4>
          </div>
        </div>

        
      </div>
    </section>
    </>
  )
}
