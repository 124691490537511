import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import slider1 from './img/CONTEMPORARY-DESIGN-1-1536x1024.jpg'
import slider2 from './img/christian-mackie-6BJu73-UJpg-unsplash-1-1536x1045.jpg'


import slider3 from './img/ECLECTIC-DESIGN_SIGN-1536x1024.jpg'

import slider4 from './img/ART-DECO-DESIGN_SIGN-1536x1024.jpg'
import slider4icon from './img/service1-4-bg.png'

const ResponsiveAutoSlider = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState(4);

  const handleResize = () => {
    if (window.innerWidth >= 1200) {
      setVisibleItems(3);
    } else if (window.innerWidth >= 992) {
      setVisibleItems(3);
    } else if (window.innerWidth >= 768) {
      setVisibleItems(2);
    } else {
      setVisibleItems(1);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % Math.ceil(items.length / visibleItems));
    }, 5000);

    return () => clearInterval(interval);
  }, [items.length, visibleItems]);

  const handleSelect = (selectedIndex) => {
    setCurrentIndex(selectedIndex);
  };

  const getItemsForSlide = (slideIndex) => {
    let slideItems = [];
    for (let i = 0; i < visibleItems; i++) {
      const index = (slideIndex * visibleItems + i) % items.length;
      slideItems.push(items[index]);
    }
    return slideItems;
  };

  const totalSlides = Math.ceil(items.length / visibleItems);

  return (
    <Carousel
      activeIndex={currentIndex}
      onSelect={handleSelect}
      indicators={false}
      controls={true}
      interval={null}
    >
      {Array.from({ length: totalSlides }).map((_, idx) => (
        <Carousel.Item key={idx}>
          <div className="d-flex justify-content-center">
            {getItemsForSlide(idx).map((item, subIdx) => (
              <div className="p-2" key={subIdx}>
                {item}
              </div>
            ))}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};


const App = () => {
  const items = [
    <div className="slider-item position-relative">
      <div className="slider-img">
        <img data-aos="fade-up" src={slider1} alt="" className="img-fluid" />
      </div>      
      <div className="bg-light p-3">        
        <h5 data-aos="fade-up" className='f-16 text-center'>CONTEMPORARY  DESIGN</h5>
        <p data-aos="fade-up" className='text-center p-90'>CONTEMPORARY DESIGN Contemporary architectural design essentially means “the style of today.” or in other words means modern, fashionable, latest, ultra-modern. But Contemporary...</p>
      </div>
    </div>,

    <div className="slider-item position-relative">
        <div className="slider-img">
            <img data-aos="fade-up" src={slider2} alt="" className="img-fluid" />
        </div>      
        <div className="bg-light p-3">        
            <h5 data-aos="fade-up" className='f-16 text-center'>HOLLYWOOD REGENCY  DESIGN</h5>
            <p data-aos="fade-up" className='text-center p-90'>HOLLYWOOD REGENCY&nbsp; DESIGN This style sometimes is called Regency Moderne. Hollywood Regency design style describes both Interior design &amp; landscape architecture characterized...</p>
        </div>
    </div>,

    <div className="slider-item position-relative">
      <div className="slider-img">
        <img data-aos="fade-up" src={slider3} alt="" className="img-fluid" />
      </div>
     
      <div className="bg-light p-3">        
        <h5  data-aos="fade-up" className=' f-16  text-center'>ECLECTIC  DESIGN</h5>
        <p  data-aos="fade-up" className='text-center p-90'>ECLECTIC  DESIGN Eclectic in Interior design jargon, meaning a style with varied elements, like a mixture of textures, time periods, styles, trends,...</p>
      </div>
    </div>,  
    
    
    <div className="slider-item position-relative">
      <div className="slider-img">
        <img data-aos="fade-up" src={slider4} alt="" className="img-fluid" />
      </div>
      
      <div className="bg-light p-3">        
        <h5 data-aos="fade-up" className='f-16 text-center'>ART DECO  DESIGN</h5>
        <p data-aos="fade-up" className='text-center p-90'>ART DECO DESIGN Art Deco design, also known as style moderne, movement in decorative arts and architecture originated in the 1920s. Subsequently,...</p>
      </div>
    </div>,
  ];

  return (
    <>
    <section className='services design-philosphy  pt-5'>
      <div className='container'>
        <div className="row">     
          <div className="App">           
            <h2 data-aos="fade-up" className='fw-bold pb-4 ps-3 text-uppercase text-dark'>DESIGN <span className='fw-normal primary-text'>PHILOSOPHY</span></h2>
            <ResponsiveAutoSlider items={items} />
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default App;
