import React from 'react'
import sliderImg from './img/slider-1.jpg'
import sliderImg2 from './img/slider-2.jpg'

export default function SliderSec() {
  return (
    
        <div id="carouselExampleFade" className="carousel slide carousel-fade w-100" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={sliderImg} className="d-block w-100" alt="..." />
                    <div className="carousel-caption  d-md-block">
                        <h2 className='fw-normal'>Transforming Spaces, Creating Experiences </h2>
                        <a><button className='btn btn-primary1 h5'>Our Portfolio</button></a>&nbsp;&nbsp;&nbsp;
                        <a><button className='btn btn-primary1 h5'>Get a Quote</button></a>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={sliderImg2} className="d-block w-100" alt="..." />
                    <div className="carousel-caption  d-md-block">
                        <h2 className='fw-normal'>Transforming Spaces, Creating Experiences </h2>
                        <a><button className='btn btn-primary1 h5'>Our Portfolio</button></a>&nbsp;&nbsp;&nbsp;
                        <a><button className='btn btn-primary1 h5'>Get a Quote</button></a>
                    </div>
                </div>                
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    
  )
}
