import {React, useState} from 'react';
import{Link} from 'react-router-dom';
import logo2 from './img/logo.png';
import logo1 from './img/A3G-logo.png';
import twitter from './img/twitter.png'
import insta from './img/instagram.png'
import face from './img/facebook.png'
import linked from './img/linkedin.png'
export default function Navbar() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleDropdownClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleNavLinkClick = () => {
        setIsDropdownOpen(false);
    };
  return (
   <>
   
   <section id='TopNavBar' className=''>
    <div className="container topBar">
        <div className='row py-2'>
            <div className='col-md-5'>
                <p className='mb-0 pb-0 text-dark'><i className="bi bi-geo-alt-fill text-dark"></i>&nbsp;&nbsp;Road No.10, Banjara hills, Hyderabad- 500 034</p>
            </div>
            

            <div className='col-md-5 ms-auto float-end text-end topHeader list-unstyled'>
                <li className='m-center'>
                    <a href=""><img data-aos="fade-up" src={face} alt="" className="img-fluid" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href=""><img data-aos="fade-up" src={twitter} alt="" className="img-fluid" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href=""><img data-aos="fade-up" src={linked} alt="" className="img-fluid" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href=""><img data-aos="fade-up" src={insta} alt="" className="img-fluid" /></a>
                </li>
                
            </div>
        </div>
    </div>
   </section>
   <nav className="navbar navbar-expand-lg navbar-light bg-white mb-70 sticky-top">
        <div className="container">
            <div className='row align-items-center'>
                <div className='col-md-2'>
                    <a className="navbar-brand" href="/">

                        <img src={logo1} className='img-fluid logo1' alt="logo" />
                        <img src={logo2} className='img-fluid logo' alt="logo" />
                    </a>
                </div>
                <div className='col-md-10'>
                <button className="navbar-toggler" type="button"  data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded={isDropdownOpen ? 'true' : 'false'} aria-label="Toggle navigation" onClick={handleDropdownClick}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                    <div className={`collapse navbar-collapse ${isDropdownOpen ? 'show' : ''}`} id="navbarScroll">
                        <ul className="navbar-nav ms-auto float-end text-end py-3 my-lg-0 navbar-nav-scroll" id='mobileMenu'>
                            <li className="nav-item">
                                <span className='nav-link' onClick={handleNavLinkClick}>
                                <Link to="/">Home</Link>
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className='nav-link' onClick={handleNavLinkClick}>
                                <Link to="about-us">About Us</Link>
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className='nav-link' onClick={handleNavLinkClick}>
                                <Link to="#">Services</Link>
                                </span>
                            </li>
                            
                            <li className="nav-item">
                                <span className='nav-link' onClick={handleNavLinkClick}>
                                <Link to="#">Portfolio</Link>
                                </span>
                            </li>
                            
                           
                            <li className="nav-item">
                                <span className='nav-link' onClick={handleNavLinkClick}>
                                <Link to="#">Contact</Link>
                                </span>
                            </li>

                            <li className="nav-item">
                                <span className='nav-link' onClick={handleNavLinkClick}>
                                    <Link className='but' to="#"> Book Consultation&nbsp;&#10509;</Link>
                                </span>
                            </li>

                            
                        </ul>                        
                    </div>
                </div>
            </div>
        </div>
      </nav>
      
    
  </>
  )
}



// Navbar.defaultProps={
//     title: 'Logo/Brand',
//     activePageName:'Active Page Name'
// }