import React from 'react'

import popImg from './img/pop-up.webp'

export default function About() {
  return (
    <section className='aboutt'>
      <div className="container py-5">
        <div className="row align-items-center justify-content-center position-relative">        
            
       
          <div className="col-md-10 home-text text-center">
              <h4 className="fw-meduim">About <span className='main-color fw-normal'>A3G INTERIO</span> </h4>
              <h5 className="text-uppercase mt-0 main-color">Transforming Your Vision into Reality</h5>
              
              <h2 className="h5 fw-normal">Welcome to A3G Interio, your trusted partner in innovative interior design solutions for both residential and commercial spaces.</h2>
              <h3 className="h6 fw-normal">Founded by <b>Ar. Ghufran Mujahid</b>, A3G Interio blends creativity with functionality to bring to life spaces that reflect your personality, style, and brand. With years of experience in architectural design and a passion for creating unique environments, Ar. Mujahid leads a dynamic team of skilled professionals dedicated to delivering exceptional designs tailored to your specific needs.</h3>
              <h3 className="h6 fw-normal">At A3G Interio, we believe in the power of collaboration. That’s why we’ve partnered with Lakhotia College of Design, Hyderabad, incorporating fresh talent from their pool of students into our projects. This synergy allows us to bring fresh, innovative perspectives to every design while providing invaluable real-world experience to the next generation of designers.</h3>
              <a className="btn border text-dark btn-flat btn-lg " href="about-us">Know More →</a>
          </div>
          <div className="col-md-6 d-none">
              <a className="video-play-button" data-toggle="modal" data-target="#myModal1">
                <span></span>
              </a>                            
                <div className="container">
                    <img src={popImg} className="img-fluid rounded-5"  data-toggle="modal" data-target="#myModal1" />
            
                    <div className="modal fade" id="myModal1" role="dialog">
                      <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title text-white bold text-center">DESIGN YOUR CAREER WITH LAKHOTIA COLLEGE</h4>
                            </div>
                            <div className="modal-body p-0">
                              <div className="video-popup123">
                                  <video loading="lazy" className="connect-bg" width="100%" height="100%" autoplay="" muted="" controls="">
                                      <source src="images/lakhotia-h.mp4" type="video/mp4" />
                                  </video>
                              </div>
                            </div>                                
                          </div>
                        </div>
                    </div>
                </div>
            </div>

          </div>
            
        </div>
     
    </section>
  )
}
